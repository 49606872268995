import React, { forwardRef, ButtonHTMLAttributes } from "react";

import ButtonSpinnerIcon from "../icons/ButtonSpinnerIcon";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "outlined" | "contained";
  loading?: boolean;
  testID?: string;
  className?: string;
  children: React.ReactNode;
}

const Button: React.FunctionComponent<ButtonProps> = forwardRef<
  HTMLButtonElement,
  ButtonProps
>(
  (
    {
      children,
      variant = "contained",
      onClick,
      loading = false,
      testID,
      className,
      ...rest
    },
    ref,
  ) => {
    let classes = [
      `text-center font-medium pointer px-8 flex items-center justify-center disabled:cursor-not-allowed disabled:opacity-60 text-base h-11 w-full rounded-md transition ease-out duration-400 hover:bg-primary-hover`,
      className,
    ];

    if (variant === "contained") {
      classes.push("text-button-text");
      classes.push("bg-primary");
    }

    if (variant === "outlined") {
      classes.push("border");
      classes.push("border-gray-200");
      classes.push("hover:!bg-gray-50");
      classes.push("text-primary");
      classes.push("bg-white");
    }

    if (loading) {
      classes.push("pointer-events-none");
    }

    return (
      <>
        <button
          ref={ref}
          className={classes.join(" ")}
          onClick={onClick}
          data-testid={testID}
          {...rest}
        >
          {loading ? <ButtonSpinnerIcon /> : children}
        </button>
        <style jsx>{`
          button {
            transition: background-color 0.4s ease-out;
          }
        `}</style>
      </>
    );
  },
);
Button.displayName = "Button";

export default Button;
