import HelpCenterButton from "@/components/support/HelpCenterButton/HelpCenterButton";
import SesamyLogo from "@/components/ui/icons/SesamyLogo";

const Footer = () => {
  return (
    <>
      <HelpCenterButton />
      <footer data-testid="footer" className="flex justify-center py-8">
        <SesamyLogo />
      </footer>
    </>
  );
};

export default Footer;
