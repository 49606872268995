import Mustache from "mustache";
import { useLanguageContext } from "@/context/language-context";

const useTranslation = () => {
  const lang = useLanguageContext();
  const t = (key: string, params?: object): string => {
    const value: string = key
      .split(".")
      .reduce(
        (previous: any, current: string) =>
          (previous && previous[current]) || null,
        lang,
      );
    const translation = value || key;
    try {
      return Mustache.render(translation, params);
    } catch (e) {
      return translation;
    }
  };

  return { t };
};

export { useTranslation };
