import { useTranslation } from "@/hooks/use-translation";

import ChatIcon from "@/components/ui/icons/ChatIcon";
import Button from "@/components/ui/button/Button";
import { defaultVendorSupportEmail } from "@/const/vendor";

const HelpCenterButton: React.FunctionComponent = () => {
  const { t } = useTranslation();

  function handleHelpCenterClick() {
    if (!!window.Intercom) {
      window.Intercom("showNewMessage");
    } else {
      window.location.href = `mailto:${defaultVendorSupportEmail}`;
    }
  }

  return (
    <div className="py-4">
      <div className="mb-4 flex items-center space-x-2">
        <img src="/images/support-team.png" alt="Support Team" />
        <span className="text-xs text-gray-500">{t("support.need_help")}</span>
      </div>
      <Button onClick={handleHelpCenterClick} variant="outlined">
        <ChatIcon className="mr-2" />
        <span className="text-sm font-medium">{t("support.help_center")}</span>
      </Button>
    </div>
  );
};

export default HelpCenterButton;
