"use client";

import { ReactNode, createContext, useContext } from "react";

export type Dictionary = { [key: string]: string | Dictionary };

const LanguageContext = createContext<Dictionary | null>(null);

type LanguageProviderProps = {
  children: ReactNode;
  dictionary: Dictionary;
};

export function LanguageProvider({
  children,
  dictionary,
}: LanguageProviderProps) {
  return (
    <LanguageContext.Provider value={dictionary}>
      {children}
    </LanguageContext.Provider>
  );
}
export function useLanguageContext() {
  return useContext(LanguageContext);
}
