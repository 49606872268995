interface ChatIconProps {
  className?: string;
}

const ChatIcon = ({ className }: ChatIconProps) => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>Chat Icon</title>
      <g clipPath="url(#clip0_1040_2267)">
        <path
          d="M3.14301 10.1303C3.34178 10.1303 3.49322 10.0383 3.72985 9.82652L5.06917 8.62951C4.80415 8.42234 4.57698 8.07244 4.57698 7.35885V4.1178C4.57698 2.6676 5.49037 1.79748 6.9811 1.79748H10.3886L10.3839 1.65477C10.2987 0.738614 9.67395 0.121704 8.54759 0.121704H1.96933C0.833514 0.121704 0.118896 0.766235 0.118896 1.88956V6.50258C0.118896 7.61206 0.819315 8.29344 1.9362 8.29344H2.69341V9.62852C2.69341 9.93238 2.85906 10.1303 3.14301 10.1303ZM11.1174 10.7933C11.4061 10.7933 11.5717 10.5953 11.5717 10.2915V8.95637H12.0592C13.176 8.95637 13.8765 8.27505 13.8765 7.16552V4.23749C13.8765 3.11417 13.1619 2.46964 12.0261 2.46964H7.12309C5.94467 2.46964 5.27267 3.10956 5.27267 4.25591V7.16552C5.27267 8.28422 5.94941 8.95637 7.12309 8.95637H8.78899L10.5353 10.4895C10.7719 10.7012 10.9186 10.7933 11.1174 10.7933Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1040_2267">
          <rect width="14" height="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChatIcon;
