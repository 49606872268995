"use client";

import Footer from "@/components/layout/Footer";
import { defaultVendorSupportEmail } from "@/const/vendor";
import { useTranslation } from "@/hooks/use-translation";
import { VendorStyle } from "@/types/profile";

interface ErrorMessageProps {
  error: string;
  vendorStyle?: VendorStyle;
  noLang?: boolean;
}

function ErrorMessage({ error, vendorStyle, noLang }: ErrorMessageProps) {
  const { t } = useTranslation();

  const supportEmail = vendorStyle?.supportEmail || defaultVendorSupportEmail;

  const noLangText =
    "Something went wrong. Reach out to our support team if you need help:";

  return (
    <div className="m-auto max-w-screen-xs py-4 xs:py-7">
      <div className="pb-6 pt-4 text-center">
        <p>{noLang ? noLangText : t(error)}</p>
        {vendorStyle?.supportUrl ? (
          <a className="text-primary" href={vendorStyle?.supportUrl}>
            {vendorStyle?.supportUrl}
          </a>
        ) : (
          <a className="text-primary" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>
        )}
      </div>
      {!noLang && <Footer />}
    </div>
  );
}

export default ErrorMessage;
