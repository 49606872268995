interface SpinnerIconProps {
  className?: string;
  height?: number;
  width?: number;
  color?: string;
}

const ButtonSpinnerIcon = ({ className }: SpinnerIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        margin: "auto",
        background: "none",
        display: "block",
        shapeRendering: "auto",
      }}
      width={34}
      height={34}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      className={className}
    >
      <title>Loading</title>
      <circle
        cx="50"
        cy="50"
        fill="none"
        className="stroke-white"
        strokeWidth="10"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  );
};

export default ButtonSpinnerIcon;
